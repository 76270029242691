import React, { useState } from "react";
import Dash from "./Extra/Dash";
import Approve from "./Steps/Approve";
import Prepare from "./Steps/Prepare";
import Send from "./Steps/Send";
import Card from 'react-bootstrap/Card';
import { strings } from "../../App";
var wizardData  = {
    token: {}, 
    csv: "" 
};
function Wizard(){

    const [step, setStep] = useState(0);
    const OnSetStep = (step) => setStep(step);
    const OnNext = () => {
        var s = step + 1;
        setStep(s);        
    }
    
    const childrens = [
        <Prepare wizardData={wizardData} onNext={OnNext}></Prepare>, 
        <Approve setStep={OnSetStep} wizardData={wizardData} onNext={OnNext}></Approve>, 
        <Send setStep={OnSetStep} wizardData={wizardData} onNext={OnNext}></Send>
    ];
    const validData = wizardData.token.balance != null && wizardData.csv.length > 0;

    return <>
        <h1 className='h1title'>{strings.sendTokensToMultiple}</h1>
        <h2 className='h2title'>{strings.tryNow}</h2>       
          <div style={{display: "flex", margin: "1rem 0rem"}}>
            <Dash currentStep={step} setStep={OnSetStep} text={strings.prepare} step={0} number={1}></Dash>
            <Dash currentStep={step} setStep={(step) => validData == true ? OnSetStep(step) : null} text={strings.approve} step={1} number={2}></Dash>
            <Dash currentStep={step} setStep={(step) => validData == true ? OnSetStep(step) : null} text={strings.send} step={2} number={3}></Dash>
        </div>
        <Card style={{ width: '100%' }}>  
            <> 
            {childrens[step]}
            </>
        </Card>
    </>
}

export default Wizard;