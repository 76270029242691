import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from "react";
import ConnectWallet, { OnConnectRequest } from './ConnectWallet';
import {
    Link
  } from "react-router-dom";
import { useWeb3React } from '@web3-react/core';
import { ContractLoader } from '../contracts/ContractLoader';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from './Logo';
import FlagDropdown from './FlagDropdown';
import { strings } from '../App';


let _callback;

export function OnVipRequest(callback){
    _callback = callback;
}

export function TriggerVipRequest(){
    if(_callback != null){
        _callback();
    }
}

function Menu(){    
    const {active, library, account, chainId} = useWeb3React();
    const [level, setLevel] = useState({
        level: 0
    });
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const LINK_STYLE = {textDecoration: "none", fontSize: "14px", color: "white", marginRight: "14px", fontWeight: "bold", cursor: "pointer", alignSelf: "center"};
    const FLAG_STYLE = {width: "34px", borderRadius: "2px"}
    const LINK_STYLE_MOBILE = {textDecoration: "none", fontSize: "14px", color: "white", fontWeight: "bold", cursor: "pointer", marginTop: "0.5rem"};
    const GOLD_BUTTON = {marginRight: "0.5rem", borderColor: "#d2c836", color: "#d2c836", fontSize: "12px", display: "flex", alignItems: "center"};
    const GOLD_BUTTON_MOBILE = {marginTop: "1rem", borderColor: "#d2c836", color: "#d2c836", fontSize: "12px", display: "flex", alignItems: "center"};
    const vip_icons = {        
        1: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} className="bi bi-1-circle"></i>, 
        2: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} className="bi bi-2-circle"></i>, 
        3: <i style={{fontSize: "18px", marginLeft: "0.55rem"}} className="bi bi-3-circle"></i>, 
    }    
    const contract = active == true ? ContractLoader(library, `${chainId}/Cryptosender`) : null;
    useEffect(() => {        
        console.log("Use Effect Called Menu");
        if(active == true && contract != null && level == null){            
            contract.contract.methods.currentLevel(account).call().then((vip) => {
                setLevel({
                    level: parseInt(vip["level"])
                });
            });
        }
    });

    return <>
    <i onClick={() => {
        setMobileMenuActive(true)
    }} style={{color: "white", fontSize: "38px", cursor: "pointer"}} className="menu-icon bi bi-list"></i>
        <Offcanvas className='backdrop-menu' show={mobileMenuActive} onHide={() => {
            setMobileMenuActive(false);
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><Logo></Logo></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{display: "flex", flexDirection: "column"}}>            
            <a title="bulktokensend.com home" style={LINK_STYLE_MOBILE} href="/"><span style={LINK_STYLE_MOBILE}>{strings.send}</span></a>
            {/* <a title="bulktokensend.com tutorial" style={LINK_STYLE_MOBILE} href="/tutorial"><span style={LINK_STYLE_MOBILE}>Tutorial</span></a> */}
            <a title="bulktokensend.com faq" style={LINK_STYLE_MOBILE} href="/faq"><span style={LINK_STYLE_MOBILE}>FAQ</span></a>    
            <Button style={GOLD_BUTTON_MOBILE} onClick={() => {
                if(_callback != null){
                    _callback();
                }
            }} className="unborder">Vip {level.level == 0 ? <></> : vip_icons[level.level]}</Button>
            <ConnectWallet></ConnectWallet>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    <div style={{display: "flex"}} className="menu">                
        <a title="bulktokensend.com home" style={LINK_STYLE} href="/"><span style={LINK_STYLE}>{strings.send}</span></a>
        {/* <a title="bulktokensend.com tutorial" style={LINK_STYLE} href="/tutorial"><span style={LINK_STYLE}>Tutorial</span></a> */}
        <a title="bulktokensend.com faq" style={LINK_STYLE} href="/faq"><span style={LINK_STYLE}>FAQ</span></a>    
        <Button style={GOLD_BUTTON} onClick={() => {
            if(_callback != null){
                _callback();
            }
        }} className="unborder">Vip {level.level == 0 ? <></> : vip_icons[level.level]}</Button>
        <ConnectWallet></ConnectWallet>
    </div></>;
}   

export default Menu;