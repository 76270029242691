import { useWeb3React } from "@web3-react/core";
import React from "react";

function Dash(props){
    const {active} = useWeb3React();
    return <div onClick={active == true ? () => props.setStep(props.step) : () => {}} className='step' style={{display: "flex",cursor: "pointer", flex: "1", alignItems: "center", opacity: props.currentStep == props.step ? "1" : "0.4"}}>
            <div style={{width: "22px", height: "22px",fontWeight: "bold",fontSize: "12px", borderRadius: "100%", border: "solid 1px white", display: "flex", justifyContent: "center", alignItems: "center"}}>{props.number}</div>
            <div style={{color: "white", margin: "0px 8px", fontSize: "12px", fontWeight: "bold"}}>{props.text}</div>
            <div style={{flexGrow: "1", background: "white", height: "1px", marginRight: "0.5rem"}} className='dash dash-width'></div>
    </div>
}


export default Dash;