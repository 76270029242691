import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import video from './tutorial.mov';
import { TriggerVipRequest } from "../components/Menu";
import { strings } from '../App';

function FAQ(){
  useEffect(() => {
    console.log("Use Effect FAQ 1");
    document.title = "bulktokensend.com - Frequently Asked Questions"
  });
    return <>
    <h3 style={{fontWeight: "bold", color: "white"}}>F.A.Q</h3>
            <Accordion style={{background: "transparent"}}>
      <Accordion.Item eventKey="0"style={{
        background: "transparent", 
        border: "solid 1px white"
      }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqWhatWalletsCan}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqWhatWalletsCanDesc}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqWhyIsSafe}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqWhyIsSafeDesc}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqWhatNetworks}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>{strings.faqWhatNetworksDesc}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqHowManyAddress}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqHowManyAddressDesc}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqCanIUse}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>      
        {strings.faqCanIUseDesc}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqCanIMakeForFree}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqCanIMakeForFreeDesc} <a href="#" onClick={() => {
          TriggerVipRequest();
        }}>here</a>.
        </Accordion.Body>
      </Accordion.Item>    
      <Accordion.Item eventKey="5" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqWhatFees}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
          {strings.faqWhatFeesDesc}      
        </Accordion.Body>
      </Accordion.Item>    
      {/* <Accordion.Item eventKey="7" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqDoYouHaveTutorial}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqDoYouHaveTutorialDesc}.<br></br>
        <video style={{width: "100%"}} width="400" controls autoPlay>
            <source src={video} type="video/mp4"></source>
        </video>
        </Accordion.Body>
      </Accordion.Item>    */}
      {/* <Accordion.Item eventKey="8" style={{
        background: "transparent", 
        border: "solid 1px white"
        }}>
        <Accordion.Header style={{fontWeight: "bold"}}>{strings.faqHowCanIContact}</Accordion.Header>
        <Accordion.Body style={{color: "white"}}>
        {strings.faqHowCanIContactDesc}: <a title="Twitter of bulktokensend.com" href="https://twitter.com/Bulktokensendio">Twitter</a>
        </Accordion.Body>
      </Accordion.Item>                         */}
    </Accordion>
    </>
}


export default FAQ;