import React, {useState, useEffect} from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useWeb3React } from "@web3-react/core";
import { ContractLoader } from "../../../contracts/ContractLoader";
import Web3 from "web3";
import { TriggerTransactionStatus } from "../../TransactionStatusToast";
import { TriggerVipRequest } from "../../Menu";
import { strings } from "../../../App";
import ReactGA from 'react-ga';

const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

function Send(props){
    const explorers = {
        1: "https://etherscan.com/tx/",
        25: "https://cronoscan.com/tx/",
        56: "https://bscscan.com/tx/", 
        97: "https://testnet.bscscan.com/tx/", 
        137: "https://polygonscan.com/tx/",
        250: "https://ftmscan.com/tx/",
        43114: "https://snowtrace.io/tx/"
    }
    const nativeSymbols = { 
        56: "BNB", 
        97: "BNB", 
        1: "ETH", 
        137: "MATIC", 
        25: "CRO", 
        250: "FMT", 
        43114: "AVAX"
    }
    const {library, account, chainId} = useWeb3React();
    const [loaded, setLoaded] = useState(false);
    const [level, setVipLevel] = useState([]);
    const [aproxCost, setAproxCost] = useState(0);
    const [sended, setSended] = useState(false);
    const [sending, setSending] = useState(false);
    const [txHash, setTxHash] = useState("");
    const COL_STYLE  = {border: "solid 1px white", borderRadius: "4px", margin: "0.25rem", display: "flex", flexDirection: "column", padding: "0.5rem"}
    var splitted     = props.wizardData.csv.split("\n");
    var total_amount = splitted.map((e) => e.split(",")[1]).map(e => parseFloat(e)).reduce((a,b) => a+b);    
    var csv = splitted.map(e => {
        var c = e.split(",");
        return {
            wallet: c[0], 
            amount: c[1]
        }
    });
    const contract = ContractLoader(library, `${chainId}/Cryptosender`);    
    useEffect(() => {
        console.log("Use Effect Send 1");
        if(loaded == false){                    
            contract.contract.methods.currentLevel(account).call().then((_level) => {
                estimateCost(csv, props.wizardData.token.address, _level, chainId).then((value) => {                                                                              
                    setAproxCost(parseFloat(Web3.utils.fromWei(value.toString())));
                    setVipLevel(_level);
                    setLoaded(true);  
                }).catch((e) => {             
                    var message = e.message;
                    var insufficientGas = message.indexOf("insufficient") != -1;
                    if(insufficientGas == true){
                        var atleast = "You need at least > " + Web3.utils.fromWei(_level["fee"], "ether") + " " + nativeSymbols[chainId];
                        TriggerTransactionStatus({message: "Insufficient funds." + atleast, show: true, error: true});
                        setTimeout(() => {
                            TriggerTransactionStatus({message: "Insufficient funds." + atleast, show: false, error: true});
                        }, 5000);
                    }
                    if(props.setStep){
                        props.setStep(1);
                    }
                })                
            });
        }
    });
    const sendTokens = async (_csv, tokenAddress, onTransactionHash = null) => {        
        var wallets = _csv.map(e => e.wallet);
        var amounts = _csv.map(e => Web3.utils.toWei(parseFloat(e.amount).toString(), "ether"));      
        var sumAmounts = _csv.map(e => {
            return (parseFloat(e.amount))
        }).reduce((a,b) => a+b);       
        if(tokenAddress == "Native chain token"){
            return contract.contract.methods.distributeEther(wallets, amounts).send({ 
                from: account,             
                value: Web3.utils.toWei((parseFloat(Web3.utils.fromWei(level["fee"], 'ether')) + sumAmounts).toString(), "ether")
            });         
        }
        return contract.contract.methods.distribute(
            tokenAddress, 
            wallets, 
            amounts
        ).send({
            from: account, 
            value: level["fee"]
        });
    }
    const estimateCost = async (_csv, tokenAddress, _level, _chainId) => {        
        var avgGasPrices = {
            137: 63000000000, 
            56: 17000000000,
            97: 17000000000, 
            25: 9700000000000, 
            250: 400000000000, 
            43114: 50000000000,
            1: 39000000000
        }
        var wallets = _csv.map(e => e.wallet);
        var amounts = _csv.map(e => {
            return (parseFloat(e.amount) * 1e18).toString()
        });     
        var sumAmounts = _csv.map(e => {
            return (parseFloat(e.amount))
        }).reduce((a,b) => a+b);             
        
        if(tokenAddress == "Native chain token"){                      
            var total = parseInt(Web3.utils.toWei( (parseFloat(sumAmounts) + parseFloat( Web3.utils.fromWei( _level["fee"] ) ) ).toString(), 'ether'));
            var r = await contract.contract.methods.distributeEther(wallets, amounts).estimateGas({ 
                from: account, 
                value: Web3.utils.toWei( (parseFloat(sumAmounts) + parseFloat( Web3.utils.fromWei( _level["fee"] ) ) ).toString(), 'ether')
            });                 
            return ((parseInt(r) * avgGasPrices[chainId]) + total).toString();    
        }       
        var r = await contract.contract.methods.distribute(tokenAddress, wallets, amounts ).estimateGas({ 
            from: account,             
            value: _level["fee"]
        });                    
        return ((parseInt(r) * avgGasPrices[chainId])).toString();
    }
    if(loaded == false){
        return <Card.Body>    
                <Card.Text style={{textAlign: "center"}}>
                    Checking...
                </Card.Text>    
            </Card.Body>
    }
    if(sended == true){
        return <Card.Body>    
            <Card.Text style={{textAlign: "center"}}>
                <h5>{strings.transactionConfirmedSuccesfully}</h5>
                <Container>
                    <Row>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.totalRecipients}</span>
                            <h4 style={{fontWeight: "bold"}}>
                                {csv.length}
                            </h4>
                        </Col>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.aproxCost}</span>
                            <h5 style={{fontWeight: "bold", fontSize: "16px"}}>                                                                                                
                            { Web3.utils.fromWei((aproxCost * 1e18).toString())} {nativeSymbols[chainId]}
                            </h5>
                        </Col>
                    </Row>
                    </Container>
                <Button style={{borderColor: "green", color: "white", marginTop: "1rem"}} onClick={() => {
                    var url = explorers[chainId] + txHash;
                    window.open(url, '_blank');
                }} className="unborder">{strings.viewTransaction}</Button>
                <br></br>
                <Button style={{borderColor: "black", color: "white", marginTop: "1rem"}} onClick={() => {
                    if(props.setStep){
                        props.setStep(0);
                    }
                }} className="unborder">{strings.doAnother}</Button>
            </Card.Text>    
        </Card.Body>
    }
    return <Card.Body>    
                <Card.Text style={{textAlign: "center"}}>
                <Container>
                    <Row>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.totalRecipients}</span>
                            <h4 style={{fontWeight: "bold"}}>
                                {csv.length}
                            </h4>
                        </Col>
                        <Col style={COL_STYLE}>
                            <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.aproxCost}</span>
                            <h5 style={{fontWeight: "bold", fontSize: "16px"}}>                                                                                                
                            {aproxCost} {nativeSymbols[chainId]}                            
                            </h5>
                        </Col>
                    </Row>
                    </Container>
                    <span onClick={() => TriggerVipRequest()} style={{fontSize: "12px", fontWeight: "bold", color: "#565698", cursor: "pointer", float: "right", margin: "1rem"}}>VIP Plan?</span>
                </Card.Text>
                <Button disabled={sending} onClick={ async () => {
                    try{
                        setSending(true);
                        TriggerTransactionStatus({show: true, message: "Sending transaction", loading: true, success: false});
                        var transaction = await sendTokens(csv, props.wizardData.token.address);
                        setSended(true);
                        setTxHash(transaction.transactionHash);
                        TriggerTransactionStatus({show: true, message: "Sended succesfully!", loading: false, success: true});
                        setTimeout(() => {
                            TriggerTransactionStatus({show: false, message: "Sended succesfully!", loading: false, success: true});
                        }, 5000);   
                        setSending(false);
                        eventTrack("ecommerce", "purchase", "Chain: " + chainId);
                    }catch(e){
                        TriggerTransactionStatus({show: true, message: "Error sending transaction. Network error."});
                        setTimeout(() => {
                            TriggerTransactionStatus({show: false, message: "Error sending transaction. Network error."});
                        }, 5000);   
                        setSending(false);
                    }
                }} className="gradient" style={{width: "100%"}} variant="primary">{strings.confirm}</Button>
            </Card.Body>
}

export default Send;