import { useEffect } from 'react';
import { strings } from '../App';
import video from './tutorial.mov';

function Tutorial(){
    useEffect(() => {
        console.log("Use Effect Tutorial 1");
        document.title = "bulktokensend.com - How to send a token to a multiple addresses?"
    });
    return <>        
        <h1 className='h1title'>{strings.tutorialToUse}</h1>
        <h2 className='h2title'>{strings.sendTokensCoinTool}</h2>
        <span style={{color: "white",  marginBottom: "2rem"}}>
            {strings.withFewSteps}
            </span>
        <video style={{width: "100%"}} width="400" controls autoplay>
            <source src={video} type="video/mp4"></source>
        </video>
    </>
}


export default Tutorial;