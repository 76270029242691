import Button from 'react-bootstrap/Button';
import React from "react";
import { useWeb3React } from '@web3-react/core';
import Wallet from './Wallet';
import Dropdown from 'react-bootstrap/Dropdown';
import { strings } from '../App';

let _callback;

export function OnLanguageChange(callback){
    _callback = callback;
}

function FlagDropdown(){
    const FLAG_STYLE = {width: "34px", borderRadius: "2px"}
    const FLAGS = [
        {code: "ES", img: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ES.svg"},
        {code: "US", img: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg"},
        {code: "IT", img: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IT.svg"},
        {code: "PT", img: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PT.svg"},
    ]
    var currentLanguage = strings.getLanguage().toUpperCase();
    var currentFlag     = FLAGS.find(e => e.code == currentLanguage) ?? FLAGS.find(e => e.code == "US");
    
    return (
        <Dropdown drop={"up"}style={{backgroundColor: "#0d6efd00 !important"}}>
          <Dropdown.Toggle style={{backgroundColor: "#0d6efd00 !important"}}>
            <img style={{width: "34px", borderRadius: "2px", marginRight: "4px"}} src={currentFlag.img} />
          </Dropdown.Toggle>
    
          <Dropdown.Menu style={{minWidth: "0.1rem", backgroundColor: "#0d6efd00", boxShadow: "rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px !important"}}>
            {FLAGS.map(e => {
                return <Dropdown.Item onClick={() => {
                    strings.setLanguage(e.code.toLowerCase());       
                    if(_callback != null){
                        _callback(e.code);
                    }                                 
                }}><img style={FLAG_STYLE} src={e.img} /></Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
}


export default FlagDropdown;