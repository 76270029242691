import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { useWeb3React } from "@web3-react/core";
import { ContractLoader } from "../../../contracts/ContractLoader";
import Web3 from "web3";
import { TriggerTransactionStatus } from "../../TransactionStatusToast";
import { strings } from "../../../App";

function Approve(props){
    const {library, account, chainId} = useWeb3React();
    const [loaded, setLoaded] = useState(false);
    const [approving, setApproving] = useState(false);
    const [hasAllowance, setHasAllowance] = useState(false);
    const COL_STYLE  = {border: "solid 1px white", borderRadius: "4px", margin: "0.25rem", display: "flex", flexDirection: "column", padding: "0.5rem"};    
    const symbol     = props.wizardData.token.symbol;    
    const balance    = props.wizardData.token.balance;
    var splitted     = props.wizardData.csv.split("\n");
    var total_amount = splitted.map((e) => e.split(",")[1]).map(e => parseFloat(e)).reduce((a,b) => a+b);    
    var csv = splitted.map(e => {
        var c = e.split(",");
        return {
            wallet: c[0], 
            amount: c[1].toString() + " " + symbol
        }
    });
    const contract = ContractLoader(library, `${chainId}/Cryptosender`);
    const erc20 = props.wizardData.token.address == "Native chain token" ? null : ContractLoader(library, "erc20", props.wizardData.token.address);
    useEffect(() => {
        console.log("Use Effect Approve 1");
        if(erc20 != null){                    
            erc20.contract.methods.allowance(account, contract.address).call().then((allowance) => {
                var allowanceParsed = parseFloat(Web3.utils.fromWei(allowance));                
                setHasAllowance(allowanceParsed >= total_amount);
                setLoaded(true);
            });
        }else{
            setHasAllowance(true);
            setLoaded(true);
        }
    });
    
    const approve = async (amount) => {
        return erc20.contract.methods.approve(contract.address, amount).send({
            from: account
        });
    }
    if(loaded == false){
        return <>
            <Card.Body>
                <span>Checking...</span>
            </Card.Body>
        </>
    }
    return <>    
         <Card.Body> 
         <Container>
            <Row>
                <Col style={COL_STYLE}>
                    <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.formatString(strings.totalOf, {symbol: symbol})}</span>
                    <h4 style={{fontWeight: "bold"}}>{total_amount} ${symbol} </h4>
                </Col>
                <Col style={COL_STYLE}>
                    <span style={{fontSize: "14px", opacity: "0.6", padding: "0.75rem"}}>{strings.formatString(strings.yourCoinBalance, {symbol: symbol})}</span>
                    <h4 style={{fontWeight: "bold"}}>{balance / 1e18} ${symbol} </h4>
                </Col>
            </Row>        
            </Container>
            <Container style={{textAlign: "left", marginTop: "1rem"}}>
            <span style={{fontWeight: "bold", fontSize: "12px"}}>{strings.deliveryList}</span>
            <ListGroup variant="flush">
                {csv.map(e => <ListGroup.Item style={{display: "flex", justifyContent: "space-between", fontSize: "14px"}}><span>{e.wallet}</span><span style={{fontWeight: "bold"}}>{e.amount}</span></ListGroup.Item>)}
            </ListGroup>
            </Container>
            {hasAllowance == false ? <Button disabled={approving} onClick={ async () => {
                try{
                    TriggerTransactionStatus({show: true, message: "Approving...", loading: true});
                    setApproving(true);
                    await approve(Web3.utils.toWei(total_amount.toString(), "ether"));
                    setApproving(false);
                    TriggerTransactionStatus({show: true, message: "Approved succesfully!", success: true});
                    setTimeout(() => {
                        TriggerTransactionStatus({show: false, message: "Approved succesfully!", success: true});
                    }, 2000);
                }catch(e){
                    TriggerTransactionStatus({show: true, message: "Error sending transaction. Network error."});
                    setTimeout(() => {
                        TriggerTransactionStatus({show: false, message: "Error sending transaction. Network error."});
                    }, 5000);  
                    setApproving(false); 
                }
            }} className="gradient" style={{width: "100%", marginTop: "1rem"}} variant="primary">{strings.approve}</Button> : <Button onClick={() => props.onNext()} className="gradient" style={{width: "100%", marginTop: "1rem"}} variant="primary">{strings.next}</Button>}
            
        </Card.Body>
    </>
}

export default Approve;