import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from "@web3-react/injected-connector";
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import CodeMirror from '@uiw/react-codemirror';
import { Typeahead } from 'react-bootstrap-typeahead';
import { EditorView } from '@codemirror/view';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import NavBar from './components/NavBar';
import { OnConnectRequest } from './components/ConnectWallet';
import { OnVipRequest } from './components/Menu';
import Wizard from './components/Wizard/Wizard';
import Web3 from 'web3';
import { OnChangeNetworkRequest } from './components/Wallet';
import FAQ from './pages/FAQ';
import { ContractLoader } from './contracts/ContractLoader';
import { OnViewCsvExampleRequest } from './components/Wizard/Steps/Prepare';
import TransactionStatusToast, { TriggerTransactionStatus } from './components/TransactionStatusToast';
import Tutorial from './pages/Tutorial';
import LocalizedStrings from 'react-localization';
import FlagDropdown, { OnLanguageChange } from './components/FlagDropdown';
import CarrouselInfo from './components/Carrousel';
import ReactGA from 'react-ga';
export var strings = new LocalizedStrings({
  en:{
    sendTokensToMultiple: "Bulk ERC20 token sender, send tokens to a multiple addresses",     
    tryNow: "Transfer batch ERC20 tokens",
    h3a: "What is Bulktokensend?",
    h4a: "Bulk crypto sender",
    body1: "Sending ERC20 tokens in batches is easy with Bulktokensend.com. Select a token from the list, enter the addresses and amounts with the indicated format and ready to send.",
    body2: "Bultokensender.com was born from the need to send ERC20 tokens to multiple recipients at once. The current naturalness of the EVM blockchain does not allow transactions to be made to multiple addresses without using smart contracts. That is why we have created a tool to make cryptocurrency batch transfers easy.",
    boxInfo1: "Bulktokensend supports the main tokens with ERC-20 protocol, such as BEP-20, ETH-20 and MATIC-20, allowing the sending of most current tokens since most are developed in ERC-20 protocol.",
    boxInfo2: "Blockchain allows the execution of highly interesting applications for the world of finance. With Bulktokensend you can make a transaction to many cryptocurrency accounts in a few minutes (sometimes seconds).",
    boxInfo3: "Sending ERC-20 tokens in bulk through our Bulktokensend tool, the tool has been tested sending to more than 200 wallets at the same time. Save time and gas costs with batch transfers.",
    prepare: "Prepare",
    approve: "Approve",
    send: "Send",
    tokenAddress: "Token address",
    csvAddressList: "CSV Address list", 
    viewExample: "View example",
    uploadFile: "Upload file",
    connectFirst: "Connect first",
    next: "Next",
    purchase: "Purchase",
    moreThanOneAccount: "More than one account must be entered",
    totalOf: "Total of ${symbol} to send",     
    yourCoinBalance: "Your ${symbol} balance", 
    deliveryList: "Delivery list",
    totalRecipients: "Total recipients",
    aproxCost: "Aprox. cost of operation",
    confirm: "Confirm",
    transactionConfirmedSuccesfully: "Transaction successfully confirmed!",
    viewTransaction: "View transaction",
    doAnother: "Do another",
    tutorialToUse: "Tutorial to use our multi sender wallet",
    sendTokensCoinTool: "Send tokens with crypto cointool app",
    withFewSteps: "With a few simple steps you can send any token to hundreds of accounts at once. To get started you can watch the video tutorial.",
    faqWhatWalletsCan: "What wallets can you use in bulktokensend.com?",
    faqWhatWalletsCanDesc: "At the moment you can use MetaMask. We are working on implementing on implementing other wallets such as WalletConnect and TrustWallet.",
    faqWhyIsSafe: "Why is it safe to use bulktokensend.com?",
    faqWhyIsSafeDesc: "To use Bulktokensendyou need to use a cryptographic wallet. The wallets supported by Bulktokensendare totally secure, because they are the most advanced on the market and are constantly audited for security. In addition, the nature of the blockchain adds an additional layer of security.",
    faqHowManyAddress: "To how many addresses at a time can I send tokens?",
    faqHowManyAddressDesc: "Bulktokensendis tested with +200 addresses at a time. Sometimes, it may fail due to congestion of the chosen network.",
    faqWhatNetworks: "What networks does bulktokensend.com operate on?",
    faqWhatNetworksDesc: "Currently it supports the main EVM networks, such as Ethereum, Binance Smart Chain, Polygon, Avalanche, Cronos and Fantom.",
    faqCanIUse: "Can I use bulktokensend.com on testnet?",
    faqCanIUseDesc: "Yes, you can select the Binance Smart Chain network (Testnet), go to the following faucet and claim some tokens to perform some tests.",
    faqWhatFees: "What fees does bulktokensend.com charge for send?",
    faqWhatFeesDesc: "bulktokensend.com applies 0,25 $TOKEN native to the selected chain. For example, if we are going to trade on Polygon 0.25 $MATIC will be applied.",
    faqCanIMakeForFree: "Can I make a transaction to multiple addresses for free?",
    faqCanIMakeForFreeDesc: "Of course you can. For this purpose bulktokensend.com has VIP plans that you can purchase with the native currency of the selected chain. You can check the plans by clicking",
    faqDoYouHaveTutorial: "Do you have any tutorial on how to use the token distributor?",
    faqDoYouHaveTutorialDesc: "Of course, here is a video tutorial on how to make a bulk delivery to different addresses with bulktokensend.com",
    faqHowCanIContact: "How can I contact you?",
    faqHowCanIContactDesc: "You can follow us on twitter and send us a message",
    vipPlanDesc: "With the vip plan, you will be able to make transactions without fees (you will only pay the network fees).",
  },
  it:{
    sendTokensToMultiple: "Mittente di token ERC20 in blocco, invio di token a più indirizzi",     
    tryNow: "Trasferimento di lotti di gettoni ERC20",
    h3a: "Che cos'è Bulktokensend?",
    h4a: "Mittente di crittografia in massa",
    body1: "Inviare token ERC20 in lotti è facile con Bulktokensend.com. Selezionate un token dall'elenco, inserite gli indirizzi e gli importi con il formato indicato e siete pronti a inviare.",
    body2: "Bultokensender.com nasce dall'esigenza di inviare token ERC20 a più destinatari contemporaneamente. L'attuale naturalezza della blockchain EVM non consente di effettuare transazioni a più indirizzi senza ricorrere a contratti intelligenti. Per questo motivo abbiamo creato uno strumento per semplificare i trasferimenti in batch di criptovalute.",
    boxInfo1: "Bulktokensend supporta i principali token con protocollo ERC-20, come BEP-20, ETH-20 e MATIC-20, consentendo l'invio della maggior parte dei token attuali, poiché la maggior parte di essi è stata sviluppata con il protocollo ERC-20.",
    boxInfo2: "La blockchain permette di realizzare applicazioni molto interessanti per il mondo della finanza. Con Bulktokensend è possibile effettuare una transazione su molti conti di criptovalute in pochi minuti (a volte secondi).",
    boxInfo3: "L'invio di token ERC-20 in blocco attraverso il nostro strumento Bulktokensend è stato testato per l'invio a più di 200 portafogli contemporaneamente. Risparmiate tempo e costi di gas con i trasferimenti in batch.",
    prepare: "Preparare",
    approve: "Approvare",
    send: "Inviare",
    tokenAddress: "Indirizzo del token",
    csvAddressList: "Elenco indirizzi in CSV",
    viewExample: "Vedi esempio",
    uploadFile: "Caricare il file",
    connectFirst: "Collegare prima",
    next: "Avanti",
    purchase: "Purchase",
    moreThanOneAccount: "More than one account must be entered",
    totalOf: "Totale di ${symbol} da inviare", 
    yourCoinBalance: "Il vostro bilancio in ${symbol}",
    deliveryList: "Lista di consegna",
    totalRecipients: "Totale dei destinatari",
    aproxCost: "Costo di esercizio approssimativo",
    confirm: "Confermare",
    transactionConfirmedSuccesfully: "Transazione confermata con successo!",
    viewTransaction: "Visualizza transazione",
    doAnother: "Fare un altro",
    tutorialToUse: "Tutorial per l'utilizzo del portafoglio multimandante",
    sendTokensCoinTool: "Inviare i token con l'applicazione crypto cointool",
    withFewSteps: "Con pochi semplici passaggi è possibile inviare qualsiasi token a centinaia di account contemporaneamente. Per iniziare potete guardare il video tutorial.",
    faqWhatWalletsCan: "Quali portafogli si possono utilizzare in bulktokensend.com?",
    faqWhatWalletsCanDesc: "Al momento è possibile utilizzare MetaMask. Stiamo lavorando per implementare altri portafogli come WalletConnect e TrustWallet.",
    faqWhyIsSafe: "Perché è sicuro usare bulktokensend.com?",
    faqWhyIsSafeDesc: "Per utilizzare Bulktokensendè necessario utilizzare un portafoglio crittografico. I portafogli supportati da Bulktokensendsono totalmente sicuri, perché sono i più avanzati sul mercato e vengono costantemente controllati per verificarne la sicurezza. Inoltre, la natura della blockchain aggiunge un ulteriore livello di sicurezza.",
    faqHowManyAddress: "A quanti indirizzi alla volta posso inviare i gettoni?",
    faqHowManyAddressDesc: "Bulktokensendviene testato con +200 indirizzi alla volta. A volte può fallire a causa della congestione della rete scelta.",
    faqWhatNetworks: "Su quali reti opera bulktokensend.com?",
    faqWhatNetworksDesc: "Attualmente supporta le principali reti EVM, come Ethereum, Binance Smart Chain, Polygon, Avalanche, Cronos e Fantom.",
    faqCanIUse: "Posso utilizzare bulktokensend.com su testnet?",
    faqCanIUseDesc: "Sì, potete selezionare la rete Smart Chain di Binance (Testnet), andare al seguente rubinetto e richiedere alcuni token per eseguire alcuni test.",
    faqWhatFees: "Quali commissioni applica bulktokensend.com per l'invio?",
    faqWhatFeesDesc: "bulktokensend.com applica 0,25 $TOKEN nativi alla catena selezionata. Ad esempio, se stiamo per negoziare su Polygon verrà applicato 0,25 $MATIC.",
    faqCanIMakeForFree: "Posso effettuare una transazione a più indirizzi gratuitamente?",
    faqCanIMakeForFreeDesc: "Naturalmente è possibile. A questo scopo bulktokensend.com dispone di piani VIP che si possono acquistare con la valuta nativa della catena selezionata. Potete controllare i piani cliccando su",
    faqDoYouHaveTutorial: "Avete qualche tutorial su come utilizzare il distributore di token?",
    faqDoYouHaveTutorialDesc: "Naturalmente, ecco un video tutorial su come effettuare una consegna massiva a diversi indirizzi con bulktokensend.com",
    faqHowCanIContact: "Come posso contattarvi?",
    faqHowCanIContactDesc: "Potete seguirci su twitter e inviarci un messaggio",
    vipPlanDesc: "Con il piano vip, potrete effettuare transazioni senza commissioni (pagherete solo le commissioni di rete).",
  }, 
  es:{
    sendTokensToMultiple: "Envío masivo de tokens ERC20, envía tokens a múltiples direcciones",     
    tryNow: "Transferencia de lotes de fichas ERC20",
    h3a: "¿Qué es Bulktokensend?",
    h4a: "Reenvío masivo de criptomonedas",
    body1: "Enviar tokens ERC20 en lotes es fácil con Bulktokensend.com. Selecciona un token de la lista, introduce las direcciones y las cantidades con el formato indicado y listo para enviar.",
    body2: "Bultokensender.com nace de la necesidad de enviar tokens ERC20 a múltiples destinatarios a la vez. La naturalidad actual de la blockchain de EVM no permite realizar transacciones a múltiples direcciones sin utilizar contratos inteligentes. Por eso hemos creado una herramienta para facilitar las transferencias por lotes de criptomonedas.",
    boxInfo1: "Bulktokensend soporta los principales tokens con protocolo ERC-20, como BEP-20, ETH-20 y MATIC-20, permitiendo el envío de la mayoría de los tokens actuales ya que la mayoría están desarrollados en protocolo ERC-20.",
    boxInfo2: "Blockchain permite la ejecución de aplicaciones muy interesantes para el mundo de las finanzas. Con Bulktokensend puedes realizar una transacción a muchas cuentas de criptomonedas en pocos minutos (a veces segundos).",
    boxInfo3: "Enviar tokens ERC-20 a granel a través de nuestra herramienta Bulktokensend, la herramienta ha sido probada enviando a más de 200 carteras al mismo tiempo. Ahorra tiempo y costes de gas con las transferencias por lotes.",
    prepare: "Preparar",
    approve: "Aprovar",
    send: "Enviar",
    tokenAddress: "Dirección del token",
    csvAddressList: "Lista de direcciones en CSV",
    viewExample: "Ver ejemplo",
    uploadFile: "Subir fichero",
    connectFirst: "Conectar primero",
    next: "Siguiente",
    purchase: "Comprar",
    moreThanOneAccount: "Debe introducirse más de una cuenta",
    totalOf: "Total de ${symbol} a enviar",    
    yourCoinBalance: "Tu balance de ${symbol}",
    deliveryList: "Lista de entrega",
    totalRecipients: "Total de beneficiarios",
    aproxCost: "Coste aprox. de la operación",
    confirm: "Confirmar",
    transactionConfirmedSuccesfully: "¡Transacción confirmada con éxito!",
    viewTransaction: "Ver transacción",
    doAnother: "Hacer otra",
    tutorialToUse: "Tutorial para utilizar nuestro monedero multiremitente",
    sendTokensCoinTool: "Enviar tokens con la aplicación crypto cointool",
    withFewSteps: "Con unos sencillos pasos puedes enviar cualquier token a cientos de cuentas a la vez. Para empezar puedes ver el video tutorial.",
    faqWhatWalletsCan: "¿Qué carteras se pueden utilizar en bulktokensend.com?",
    faqWhatWalletsCanDesc: "Por el momento se puede utilizar MetaMask. Estamos trabajando en la implementación de otros monederos como WalletConnect y TrustWallet.",
    faqWhyIsSafe: "¿Por qué es seguro utilizar bulktokensend.com?",
    faqWhyIsSafeDesc: "Para utilizar Bulktokensendes necesario utilizar un monedero criptográfico. Los monederos soportados por Bulktokensendson totalmente seguros, porque son los más avanzados del mercado y son constantemente auditados en cuanto a seguridad. Además, la naturaleza del blockchain añade una capa adicional de seguridad.",
    faqHowManyAddress: "¿A cuántas direcciones puedo enviar fichas a la vez?",
    faqHowManyAddressDesc: "Bulktokensendha sido probada con +200 direcciones a la vez. A veces, puede fallar debido a la congestión de la red elegida.",
    faqWhatNetworks: "¿En qué redes opera bulktokensend.com?",
    faqWhatNetworksDesc: "Actualmente soporta las principales redes EVM, como Ethereum, Binance Smart Chain, Polygon, Avalanche, Cronos y Fantom.",
    faqCanIUse: "¿Puedo utilizar bulktokensend.com en testnet?",
    faqCanIUseDesc: "Sí, puedes seleccionar la red Binance Smart Chain (Testnet), ir al siguiente grifo y reclamar algunos tokens para realizar algunas pruebas.",
    faqWhatFees: "¿Qué tarifas cobra bulktokensend.com por el envío?",
    faqWhatFeesDesc: "bulktokensend.com aplica 0,25 $TOKEN nativos a la cadena seleccionada. Por ejemplo, si vamos a operar en Polygon se aplicará 0,25 $MATIC.",
    faqCanIMakeForFree: "¿Puedo hacer una transacción a varias direcciones de forma gratuita?",
    faqCanIMakeForFreeDesc: "Por supuesto que sí. Para ello bulktokensend.com dispone de planes VIP que puedes adquirir con la moneda nativa de la cadena seleccionada. Puede consultar los planes haciendo clic en",
    faqDoYouHaveTutorial: "¿Teneis algún tutorial sobre cómo utilizar el distribuidor de tokens?",
    faqDoYouHaveTutorialDesc: "Por supuesto, aquí hay un video tutorial sobre cómo hacer un envío masivo a diferentes direcciones con bulktokensend.com",
    faqHowCanIContact: "¿Cómo puedo contactar con ustedes?",
    faqHowCanIContactDesc: "Puedes seguirnos en twitter y enviarnos un mensaje",
    vipPlanDesc: "Con el plan vip, podrás realizar transacciones sin comisiones (sólo pagarás las comisiones de la red).",
  }, 
  pt:{
    sendTokensToMultiple: "Remetente de fichas ERC20 a granel, enviar fichas para um endereço múltiplo",     
    tryNow: "Transferência de fichas ERC20 do lote",
    h3a: "O que é o Bulktokensend?",
    h4a: "Remetente criptográfico a granel",
    body1: "O envio de fichas ERC20 em lotes é fácil com Bulktokensend.com. Seleccione um token da lista, introduza os endereços e quantidades com o formato indicado e pronto a enviar.",
    body2: "Bultokensender.com nasceu da necessidade de enviar fichas ERC20 a múltiplos destinatários de uma só vez. A naturalidade actual da cadeia de bloqueio EVM não permite a realização de transacções para múltiplos endereços sem a utilização de contratos inteligentes. É por isso que criámos uma ferramenta para facilitar as transferências de lotes de moedas criptográficas.",
    boxInfo1: "Bulktokensend apoia os principais tokens com protocolo ERC-20, tais como BEP-20, ETH-20 e MATIC-20, permitindo o envio da maioria dos tokens actuais uma vez que a maioria é desenvolvida no protocolo ERC-20.",
    boxInfo2: "A Blockchain permite a execução de aplicações altamente interessantes para o mundo das finanças. Com o Bulktokensend é possível fazer uma transacção para muitas contas em moeda criptográfica em poucos minutos (por vezes segundos).",
    boxInfo3: "Enviando fichas ERC-20 a granel através da nossa ferramenta de envio a granel, a ferramenta foi testada e enviada para mais de 200 carteiras ao mesmo tempo. Poupe tempo e custos de gás com transferências de lotes.",
    prepare: "Preparar",
    approve: "Aprovar",
    send: "Enviar",
    tokenAddress: "Direccion del token",
    csvAddressList: "Lista de endereços em CSV",
    viewExample: "Ver exemplo",
    uploadFile: "Carregar ficheiro",
    connectFirst: "Ligar primeiro",
    next: "Próximo",
    purchase: "Purchase",
    moreThanOneAccount: "Mais do que uma conta deve ser introduzida",
    totalOf: "Total de ${symbol} a enviar",    
    yourCoinBalance: "O seu balanço do ${symbol}",
    deliveryList: "Lista de entrega",
    totalRecipients: "Total de destinatários",
    aproxCost: "Custo aprox. da operação",
    confirm: "Confirme",
    transactionConfirmedSuccesfully: "Transacção confirmada com sucesso!",
    viewTransaction: "Ver transacção",
    doAnother: "Faça outro",
    tutorialToUse: "Tutorial para usar a nossa carteira de multi-cartão", 
    sendTokensCoinTool: "Enviar fichas com aplicação crypto cointool",
    withFewSteps: "Com alguns passos simples, pode enviar qualquer ficha para centenas de contas de uma só vez. Para começar, pode assistir ao vídeo tutorial.",
    faqWhatWalletsCan: "Que carteiras pode usar no bulktokensend.com?",
    faqWhatWalletsCanDesc: "Neste momento pode utilizar a MetaMask. Estamos a trabalhar na implementação de outras carteiras, tais como a WalletConnect e a TrustWallet.",
    faqWhyIsSafe: "Porque é seguro usar o bulktokensend.com?",
    faqWhyIsSafeDesc: "Para utilizar o criptosensor é necessário utilizar uma carteira criptográfica. As carteiras suportadas pelo criptosensor são totalmente seguras, porque são São os mais avançados no mercado e são constantemente auditados quanto à segurança. Além disso, a natureza da cadeia de bloqueio acrescenta uma camada adicional de segurança.",
    faqHowManyAddress: "A quantos endereços de cada vez posso enviar fichas?",
    faqHowManyAddressDesc: "O Bulktokensendé testado com +200 endereços de cada vez. Por vezes, pode falhar devido ao congestionamento da rede escolhida.",
    faqWhatNetworks: "Em que redes opera o bulktokensend.com?",
    faqWhatNetworksDesc: "Actualmente suporta as principais redes EVM, tais como Ethereum, Binance Smart Chain, Polygon, Avalanche, Cronos, Fantom.",
    faqCanIUse: "Posso usar o bulktokensend.com no testnet?",
    faqCanIUseDesc: "Sim, pode seleccionar a rede Binance Smart Chain (Testnet), ir à seguinte torneira e reclamar alguns tokens para realizar alguns testes.",
    faqWhatFees: "Que taxas é que o bulktokensend.com cobra pelo envio?",
    faqWhatFeesDesc: "bulktokensend.com aplica 0,25 $TOKEN nativo à cadeia seleccionada. Por exemplo, se formos negociar no Polygon 0,25 $MATIC será aplicado.",
    faqCanIMakeForFree: "Posso fazer uma transacção para múltiplos endereços gratuitamente?",
    faqCanIMakeForFreeDesc: "É claro que pode. Para este efeito, o bulktokensend.com tem planos VIP que pode adquirir com a moeda nativa da cadeia seleccionada. Pode verificar os planos, clicando em",
    faqDoYouHaveTutorial: "Tem algum tutorial sobre como utilizar o distribuidor de fichas?",
    faqDoYouHaveTutorialDesc: "Claro, aqui está um tutorial em vídeo sobre como fazer uma entrega em massa para diferentes endereços com bulktokensend.com",
    faqHowCanIContact: "Como posso contactá-lo?",
    faqHowCanIContactDesc: "Pode seguir-nos no twitter e enviar-nos uma mensagem",
    vipPlanDesc: "Com o plano vip, poderá fazer transacções sem taxas (pagará apenas as taxas de rede)."
  }
});


const Injected = new InjectedConnector({
  supportedChainIds: [1337, 56, 97, 1, 137, 25, 250, 43114]
});

function App() {
  const { activate, deactivate, active, library, account, chainId, error} = useWeb3React();
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState("us");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showVip, setShowVip] = useState(false);
  const handleCloseVip = () => setShowVip(false);
  const handleShowVip = () => {    
    setShowVip(true);
  };

  const [showChangeNetwork, setShowChangeNetwork] = useState(false);
  const handleCloseChangeNetwork = () => setShowChangeNetwork(false);
  const handleShowChangeNetwork = () => setShowChangeNetwork(true);

  const [showCSVExample, setShowCSVExample] = useState(false);
  const handleCloseCSVExample = () => setShowCSVExample(false);
  const handleShowCSVExample = () => setShowCSVExample(true);
  const [asking, setAsking] = useState(false);

  const contractsUrls = {
    1: "https://etherscan.io/address/0xd224d48f34058a089885b6e8f0babfd8803b32f2#code", 
    25: "https://cronoscan.com/address/0x85f292dbdB22c3014A6E52c00c542A24e0599A7e",
    56: "https://bscscan.com/address/0xEf765f81AD2909a187223c032c19BA4cD87239fb#code",
    97: "https://testnet.bscscan.com/address/0xEf765f81AD2909a187223c032c19BA4cD87239fb#code",
    137: "https://polygonscan.com/address/0x52d10EA94ac258Eb791CD9be831EE06fcCe80F00#cod",
    250: "https://ftmscan.com/address/0xEf765f81AD2909a187223c032c19BA4cD87239fb",
    43114: "https://snowtrace.io/address/0x38054F936Fa8F7caD6212EE9e61ADAD924896589"
  }
  const trackingId = "UA-247666523-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  OnConnectRequest(() => handleShow());
  OnVipRequest(() => handleShowVip());
  OnChangeNetworkRequest(() => handleShowChangeNetwork());
  OnViewCsvExampleRequest(() => handleShowCSVExample());
  // strings.setLanguage("pt");
  OnLanguageChange((code) => {
      setLanguage(code);
      strings.setLanguage(code.toLowerCase());
  });
  useEffect(() => {    
    if(active == true){      
      GetNativeBalance(account, library).then(e =>{        
        localStorage.setItem("balance", e);
      });
    }else{
      if(localStorage.getItem("account") != null){                
        if(!error){                    
          activate(Injected).then(() => {
            setAsking(true);
          }).catch((e) => {
            setAsking(false);
          });
        }
      }
    }
  })
  
  return (
    <div className="App">
      <NavBar></NavBar>
      <div className="App-Body">            
        <Router>
          <Routes>
            <Route path="/" element={<Wizard></Wizard>} />
            <Route path="/tutorial" element={<Tutorial></Tutorial>} />
            <Route path="/faq" element={<FAQ></FAQ>} />
          </Routes>
        </Router>
      </div>
      <div className='App-Body' style={{paddingTop: "1rem"}}>      
      <div className='App-Section-Body-1'>
      <CarrouselInfo></CarrouselInfo>
          {/* <div className='App-Box-Info'>
            <h4 className='h4' style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              Crypto Multi Sender <i style={{fontSize: "24px", marginLeft: "1rem"}} class="bi bi-arrows-move"></i>
            </h4>            
            <p style={{fontSize: "14px"}}>
              {strings.boxInfo1}
            </p>
          </div>
          <div className='App-Box-Info'>
            <h4 className='h4' style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              Multichain <i style={{fontSize: "24px", marginLeft: "1rem"}}  class="bi bi-bounding-box-circles"></i>
            </h4>
            <p style={{fontSize: "14px"}}>
              {strings.boxInfo2}
                     
            </p>
          </div>
          <div className='App-Box-Info'>
          <h4 className='h4' style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              Time saver <i style={{fontSize: "24px", marginLeft: "1rem"}}  class="bi bi-clock"></i>
            </h4>
            <p style={{fontSize: "14px"}}>              
              {strings.boxInfo3}
            </p>
          </div> */}
      </div> 
      <h3 style={{color: "white", fontWeight: "bold", fontSize: "22px", marginTop: "1rem"}} className='h1'>
        {strings.h3a}
      </h3>
      <p style={{color: "white"}}>
            {strings.body1}
        </p>

      <hr style={{color: "white"}}></hr>
      <div className='App-Section-Body-2'>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <h4 style={{color: "white", textAlign: "left", fontWeight: "bold", margin: "0px"}}>
            {strings.h4a}
          </h4>
          <i style={{fontSize: "32px", color: "white", marginLeft: "1rem"}} class="bi bi-boxes"></i>        
        </div>
        <p style={{color: "white", textAlign: "left"}}>
          {strings.body2}
        </p>                
      </div>

      </div>      
      <div className="App-Footer">
        {/* <span style={{ fontWeight: "bold", fontSize: "14px", color: "white" }}>Version: 0.1</span> */}
        {/* <hr></hr> */}
        <div className='footer'>
          <div style={{ marginTop: "0.5rem" }}>
            <a title="contract url" target="__blank" href={!chainId ? contractsUrls[56] : contractsUrls[chainId]} style={{ textDecoration: "none", color: "white" }}>Contract<i style={{ marginLeft: "0.5rem", color: "#76ff76" }} className="bi bi-patch-check-fill"></i></a>
          </div>
          <div style={{ marginTop: "0.5rem" , display: "flex"}}>
            <FlagDropdown></FlagDropdown>
            {/* <a style={{ textDecoration: "none", color: "white" }}  target="__blank" href="https://telegram.com/CryptoMultiSender">
              <i style={{ fontSize: "24px" }} className="bi bi-telegram"></i>
            </a> */}
            {/* <a title="BulktokensendTwitter" style={{ textDecoration: "none", color: "white", marginLeft: "0.75rem" }} target="__blank" href="https://twitter.com/Bulktokensendio">
              <i style={{ fontSize: "24px" }} className="bi bi-twitter"></i>
            </a>
            <a title="BulktokensendGithub" style={{ textDecoration: "none", color: "white", marginLeft: "0.75rem" }} target="__blank" href="https://github.com/stormdapps/Bulktokensend">
              <i style={{ fontSize: "24px" }} className="bi bi-github"></i>
            </a> */}
          </div>
        </div>
      </div>
      <ModalWallet handleClose={() => { handleClose() }} show={show}></ModalWallet>
      <ModalVip handleClose={() => { handleCloseVip() }} show={showVip}></ModalVip>
      <ModalNetworks handleClose={() => handleCloseChangeNetwork()} show={showChangeNetwork}></ModalNetworks>
      <ModalCsvExample handleClose={() => handleCloseCSVExample()} show={showCSVExample}></ModalCsvExample>
      <TransactionStatusToast></TransactionStatusToast>
    </div>
  );
}

export async function GetNativeBalance(account, library) {
  return library.getBalance(account);
}

export async function GetTokens(address, network) {
  var chain = "0x"+network.toString(16);
  var nativeBalance = localStorage.getItem("balance") ?? 0;
  const nativeTokens = {
    56: {
      symbol: "BNB", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    97: {
      symbol: "BNB", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    1: {
      symbol: "ETH", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    137: {
      symbol: "MATIC", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    25: {
      symbol: "CRO", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    250: {
      symbol: "FTM", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
    43114: {
      symbol: "AVAX", 
      balance: nativeBalance.toString(), 
      token_address: "Native chain token"
    },
  }
  var native = nativeTokens[network];
  var url = "https://deep-index.moralis.io/api/v2/" + address + "/erc20?chain=" + chain;
  var fetched = await fetch(url, {
    headers: {
      "X-API-Key": "leQNTj306UJXTgMN1PKRHbvnOqFqjgXBjeiVQDoIuMEfqyR2BKfkyUpjsO22Q2rS",
      "Accept": "application/json"
    }
  });
  var json = await fetched.json();
  json.push(native);
  return json;
}

function ModalWallet(props) {
  const { activate, deactivate } = useWeb3React();
  const { active, chainId, account } = useWeb3React();
  if(active == true){
    if(account){
       localStorage.setItem("account", account);
    }
  }
  return (
    <>
      <Modal show={props.show}>
        <Modal.Header closeButton onHide={props.handleClose}>
          <Modal.Title style={{color:"black"}}>Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item
              style={{ cursor: "pointer", display: "flex", alignItems: "center", color: "black !important" }}
              onClick={async () => {
                await activate(Injected);                
                props.handleClose();
              }}><img alt="metamask logo" src={"https://seeklogo.com/images/M/metamask-logo-09EDE53DBD-seeklogo.com.png"} style={{ width: "20px", marginRight: "0.5rem" }}></img><span style={{color: "black"}}>Metamask</span></ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}
function ModalCsvExample(props) {
  const { activate, deactivate } = useWeb3React();
  const { active, chainId, account } = useWeb3React();
  return (
    <>
      <Modal size="lg" centered show={props.show}>
        <Modal.Header closeButton onHide={props.handleClose}>
          <Modal.Title style={{color: "black"}}>Example csv</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <CodeMirror theme={EditorView.theme({
          "&": {
            backgroundColor: "#fff0"
          },
          ".cm-activeLine": { backgroundColor: "#fff0",caretColor: "black" },
          ".cm-gutters": {backgroundColor: "#fff0"},
          ".cm-activeLineGutter": {backgroundColor: "#fff0"},
          ".cm-cursor": {borderLeft: "1.2px solid black"},
          ".cm-line": {color: "black", paddingLeft: "1rem"},
          ".cm-lineNumbers": {color: "black"},
          ".cm-content": {caretColor: "black !important"}
        })}  value={"0x18fe3C91Ff0F71827146f03E120D8A26D39B5339, 0.0005 \n0x18fe3C91Ff0F71827146f03E120D8A26D39B5339, 0.0005"} height="200px"/>
        </Modal.Body>
      </Modal>
    </>
  );
}

function GetVipPrive(chainId, unit){
  if(chainId == 56){
    return unit + " BNB";
  }
  if(chainId == 97){
    return unit + " T-BNB";
  }
  if(chainId == 137){
    return unit + " MATIC";
  }
  if(chainId == 25){
    return unit + " CRO";
  }
  if(chainId == 250){
    return unit + " FMT";
  }
  if(chainId == 43114){
    return unit + " AVAX";
  }
  return unit + " ETH";
}

function ModalVip(props) {
  const {chainId, library, account, active} = useWeb3React();  
  const [purchased, setPurchased] = useState([]);
  const [purchasedLoaded, setPurchasedLoaded] = useState(false);
  var loaded = false;
  const vips = [
    {level: 1, text: "Vip Lv. 1 (1 day)", price: GetVipPrive(chainId, 1), priceAmount: Web3.utils.toWei('1', 'ether') },
    {level: 2, text: "Vip Lv. 2 (7 days)", price: GetVipPrive(chainId, 3), priceAmount: Web3.utils.toWei('3', 'ether') },
    {level: 3, text: "Vip Lv. 3 (30 days)", price: GetVipPrive(chainId, 5), priceAmount: Web3.utils.toWei('5', 'ether') },
    {level: 4, text: "Vip Lv. 4 (90 days)", price: GetVipPrive(chainId, 10), priceAmount: Web3.utils.toWei('10', 'ether') },
  ]
  const contract =  active == true ? ContractLoader(library, `${chainId}/Cryptosender`) : null;
  const info = strings.vipPlanDesc;
  const purchase = async (level) => {    
    if(contract == null){
      return;
    }
    return contract.contract.methods.purchaseVip(level).send({
      from: account, 
      value: vips.find(e => e.level == level).priceAmount
    });
  }
  const getPurchased = async () => {
    if(contract == null){
      return;
    }
    return contract.contract.methods.currentLevel(account).call();
  }
  
  useEffect(() => {   
    console.log("Purchase loaded called " + purchasedLoaded);
    if(purchasedLoaded == false){
      loadPurchased();
    }
  });
  const loadPurchased = () => {
    getPurchased().then((purchased) => {
      if(!purchased){
        return;
      }
      setPurchased(purchased);      
      setPurchasedLoaded(true);
    });
  }
  return <>
    <Modal show={props.show}>
      <Modal.Header closeButton onHide={props.handleClose}>
        <Modal.Title style={{color: "black"}}>Vip plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ borderRadius: "4px", background: "rgba(0,0,0,0.1)", padding: "0.5rem" }}>
          <span style={{ fontSize: "12px", color: "black" }}>{info}</span>
        </div>
        <ListGroup>
          {vips.map(e => {
            var isPurchased = !purchased ? false : purchased["level"] == e.level;            
            return <ListGroup.Item key={e.level} className="separated" style={{ cursor: "pointer", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "14px", color: "black"}}>{e.text}</span>
              <div style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}><span style={{ marginRight: "0.5rem", color: "black" }}>{e.price}</span> 
              {!active || !account ? <Button disabled className="gradient" style={{ fontWeight: "bold", fontSize: "12px" }} variant="primary">Connect first</Button> : 
              <Button disabled={isPurchased} onClick={isPurchased == true ? () => {} : async () => {             
                TriggerTransactionStatus({message: "Purchasing vip", load: true, show: true});
                try{
                  await purchase(e.level);
                  TriggerTransactionStatus({message: "Success purchase", success: true, show: true});
                  setTimeout(() => {
                    TriggerTransactionStatus({message: "Success purchase", success: true, show: true});
                    loadPurchased();
                  }, 5000);
                }catch(e){                  
                  TriggerTransactionStatus({message: "Error purchasing vip", load: true, show: true, error: true});
                  setTimeout(() => {
                    TriggerTransactionStatus({message: "Error purchasing vip", load: true, show: true, error: true});
                  }, 5000);
                }            
              }} className="gradient" style={{ fontWeight: "bold", fontSize: "12px" }} variant="primary">{isPurchased == true ? "Purchased" : strings.purchase}</Button>} </div>
            </ListGroup.Item>
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  </>
}
function ModalNetworks(props) {
  const {chainId, library} = useWeb3React();
  const mainNetworks = [
    { 
      text: "BSC (Binance Smart Chain)", 
      icon: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
      chainId: 56, 
      chainName: "Binance Smart Chain", 
      symbol: "BNB", 
      explorer: "https://bscscan.com/",
      rpc: "https://bsc-dataseed1.binance.org/"
    },
    { 
      text: "ETH (Ethereum Mainnet)", 
      icon: "https://cdn4.iconfinder.com/data/icons/cryptocoins/227/ETH-alt-512.png", 
      chainId: 1, 
      chainName: "Ethereum Mainnet", 
      symbol: "ETH",
      explorer: "https://etherscan.io/",
      rpc: "https://rpc.ankr.com/eth"
    },
    { 
      text: "MATIC (Polygon Mainnet)", 
      icon: "https://cdn3d.iconscout.com/3d/premium/thumb/polygon-matic-cryptocurrency-5108587-4263924.png", 
      chainId: 137, 
      chainName: "Polygon", 
      symbol: "MATIC", 
      explorer: "https://polygonscan.com/",
      rpc: "https://polygon-rpc.com"
    },
    { 
      text: "BSC (Binance Smart Chain Testnet)", 
      icon: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
      chainId: 97, 
      chainName: "Binance Smart Chain (Testnet)", 
      symbol: "BNB", 
      explorer: "https://testnet.bscscan.com/",
      rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/"
    },
    { 
      text: "AVAX (Avalanche Mainnet)", 
      icon: "https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=023", 
      chainId: 43114, 
      chainName: "Avalanche Mainnet", 
      symbol: "AVAX", 
      explorer: "https://snowtrace.io/",
      rpc: "https://api.avax.network/ext/bc/C/rpc"
    },
    { 
      text: "FTM (Fantom Mainnet)", 
      icon: "https://cryptologos.cc/logos/fantom-ftm-logo.png", 
      chainId: 250, 
      chainName: "Fantom Mainnet", 
      symbol: "FTM", 
      explorer: "https://ftmscan.com/",
      rpc: "https://rpcapi.fantom.network/"
    },
    { 
      text: "CRO (Cronos Mainnet)", 
      icon: "https://cryptologos.cc/logos/cronos-cro-logo.png", 
      chainId: 25, 
      chainName: "Cronos Mainnet", 
      symbol: "CRO", 
      explorer: "https://cronoscan.com/",
      rpc: "https://evm.cronos.org"
    },
  ]
  const switchNetwork = async (chainId) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + chainId.toString(16) }],
      });
    } catch (switchError) {
      // 4902 error code indicates the chain is missing on the wallet
      if (switchError.code === 4902) {
        try {
          var network = mainNetworks.find(e => e.chainId == chainId);
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId:  "0x" + chainId.toString(16),
                rpcUrls: [network.rpc],
                chainName: network.chainName,
                nativeCurrency: { name: network.symbol, decimals: 18, symbol: network.symbol },
                blockExplorerUrls: [network.explorer],
                iconUrls: [network.icon]
              }
            ],
          });
        } catch (error) {
           console.error(error)
        }
      }
    }
  };
  return <>
    <Modal show={props.show}>
      <Modal.Header closeButton onHide={props.handleClose}>
        <Modal.Title style={{color: "black"}}>Choose a network</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {mainNetworks.map(e => {
            var current = e.chainId == chainId;
            return <ListGroup.Item key={e.chainId} onClick={async () => {
              await switchNetwork(e.chainId);
              props.handleClose();
            }} className="separated" style={{ cursor: "pointer", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <div><span><img alt={e.symbol + " icon"} src={e.icon} style={{ width: "20px" }}></img></span><span style={{ marginLeft: "14px", fontSize: "12px", color: "black" }}>{e.text}</span>{current == true ? <i style={{ marginLeft: "0.5rem", color: "#2b892b" }} className="bi bi-patch-check-fill"></i> : <></>}</div>
            </ListGroup.Item>
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  </>
}

export function Loader(){
  return <>
        <div className="loadingio-spinner-rolling-o18mgj7sztd"><div className="ldio-dwpebok3bgh">
      <div></div>
      </div></div>
  </>
}


export default App;
