
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Loader } from '../App';
import Spinner from 'react-bootstrap/Spinner';

var callback;

export function OnTransactionStatus(_callback){
    callback = _callback;
}

export function TriggerTransactionStatus(options){
    callback(options.show, options.message, options?.loading ?? false, options?.success ?? false, options?.error ?? false);
}

export default function TransactionStatusToast(props){
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    OnTransactionStatus((_show, _message, loading, success, error) => {        
        setShow(_show);
        setMessage(_message);
        setLoading(loading);
        setSuccess(success);
        setError(error);
    });
    return  <ToastContainer style={error == true ? {backgroundColor: "#ff7d7d !important"} : {}} className="p-3" position={"top-start"}>
    <Toast style={error == true ? {backgroundColor: "#ff7d7d !important"} : {}} show={show}>
      <Toast.Header closeButton={false}>
        <strong className="me-auto">Transaction status</strong>        
      </Toast.Header>
      <Toast.Body style={{textAlign: "left", color: error == true ? "white" : "black", backgroundColor:  error == true ? "#E91313" : "inherit"}}>
        {message} 
        {success == true ? <i style={{marginLeft: "0.5rem", color: "green" }} className="bi bi-patch-check-fill"></i> : <></>}
        {loading == true ?     
         <Spinner size={"sm"} style={{marginLeft: "0.5rem"}} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner> : <></>}
      </Toast.Body>
    </Toast>
  </ToastContainer>
}