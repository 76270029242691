import Carousel from 'react-bootstrap/Carousel';
import { strings } from '../App';

function CarrouselInfo() {
  const chainIcons = {
    56: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png",
    97: "https://seeklogo.com/images/B/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com.png", 
    1: "https://cdn4.iconfinder.com/data/icons/cryptocoins/227/ETH-alt-512.png", 
    137: "https://cdn3d.iconscout.com/3d/premium/thumb/polygon-matic-cryptocurrency-5108587-4263924.png",
    25: "https://cryptologos.cc/logos/cronos-cro-logo.png", 
    250: "https://cryptologos.cc/logos/fantom-ftm-logo.png", 
    43114: "https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=023"
}
  return (
    <Carousel fade={true} interval={4000} controls={false}>
      <Carousel.Item tabIndex={0} key={1} style={{display: "flex",alignItems: "center", justifyContent: "center", flexDirection: "column", height: "245px", padding: "0.25rem", background: "#d2c836", borderRadius: "8px"}}>        
        <h3 style={{fontWeight: "bold", color: "black"}}>ERC-20</h3>
        <p style={{paddingLeft:"4rem", paddingRight: "4rem", color: "black"}}>{strings.boxInfo1}</p>        
      </Carousel.Item>
      <Carousel.Item tabIndex={1} key={2} style={{display: "flex",alignItems: "center", justifyContent: "center", flexDirection: "column", height: "245px", padding: "0.25rem", background: "#d2c836", borderRadius: "8px"}}>        
        <h3 style={{fontWeight: "bold", color: "black"}}>Blockchain technology</h3>
        <p style={{paddingLeft:"4rem", paddingRight: "4rem", color: "black"}}>{strings.boxInfo2}</p>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <img style={{width: "32px", marginLeft: "8px"}} src={chainIcons[56]} alt='binance bsc icon'></img>
          <img style={{width: "32px", marginLeft: "8px"}} src={chainIcons[1]} alt='ethereum eth icon'></img>
          <img style={{width: "42px", marginLeft: "4px"}} src={chainIcons[137]} alt='polygon matic icon'></img>
          <img style={{width: "32px", marginLeft: "4px"}} src={chainIcons[25]} alt='cronos matic icon'></img>
          <img style={{width: "32px", marginLeft: "4px"}} src={chainIcons[43114]} alt='avalanche matic icon'></img>
          <img style={{width: "32px", marginLeft: "4px"}} src={chainIcons[250]} alt='fantom matic icon'></img>
        </div>
      </Carousel.Item>
      <Carousel.Item tabIndex={2} key={3} style={{display: "flex",alignItems: "center", justifyContent: "center", flexDirection: "column", height: "245px", padding: "0.25rem", background: "#d2c836", borderRadius: "8px"}}>        
        <h3 style={{fontWeight: "bold", color: "black"}}>Bulk crypto</h3>
        <p style={{paddingLeft:"4rem", paddingRight: "4rem", color: "black"}}>{strings.boxInfo3}</p>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarrouselInfo;